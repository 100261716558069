import flux from 'core/flux';
import SegmentTracking from 'optly/modules/segment';
import { actions as MetricsManagerModuleActions } from 'bundles/p13n/modules/metrics_manager';
import { getters as ViewGetters } from 'optly/modules/entity/view';
import { getters as EventGetters } from 'optly/modules/entity/event';
import {
  fns as MetricFns,
  constants as MetricConstants,
} from 'optly/modules/entity/metric';
import ProjectActions from 'optly/modules/entity/project/actions';
import ProjectEnums from 'optly/modules/entity/project/enums';
import { actions as CurrentLayerActions } from 'bundles/p13n/modules/current_layer';

import {
  buildMetricFormFromWrapper,
  buildMetricWrapper,
} from '../../../metrics_modal/utils';
import { GLOBAL_EVENTS, METRIC_TYPES } from '../../../metrics_modal/constants';
import { getters as MetricsPickerModuleGetters } from './component_module';

export function saveMetricFromModal({
  metricForm,
  metricWrapper,
  workingMetricWrappers,
  layer,
}) {
  const isFilteringOnProperties =
  metricForm.filterByProperties && Object.keys(metricForm.conditions).length > 0;

  if (isFilteringOnProperties) {
    const numberOfProperties = Object.keys(metricForm.conditions).length;
    const propertyTypes = Object.values(metricForm.conditions).map(
      (condition) => condition.type,
    );
    SegmentTracking.tracking.trackEvent('Metric Created Using Filtering on Event Properties', {
      metricName: metricForm.name !== '' ? metricForm.name : metricForm.event.name,
      eventName: metricForm.event.name,
      numberOfProperties,
      propertyTypes,
    });
  }
  const updatedMetricWrapper = buildMetricWrapper(metricForm, layer);

  let metricWrappers;

  if (metricWrapper) {
    metricWrappers = workingMetricWrappers.map(wrapper => {
      if (
        wrapper.get('metric').get('alias') ===
        updatedMetricWrapper.get('metric').get('alias')
      ) {
        return updatedMetricWrapper;
      }
      return wrapper;
    });
  } else {
    metricWrappers = workingMetricWrappers.push(updatedMetricWrapper);
  }

  const updatedWorkingMetricWrappers = MetricFns.createMetricWrappers(
    metricWrappers.map(wrapper => wrapper.get('metric')),
    layer,
    flux.evaluate(ViewGetters.entityCache),
    flux.evaluate(EventGetters.entityCache),
  );

  MetricsManagerModuleActions.updateWorkingMetricWrappers(
    updatedWorkingMetricWrappers,
  );
}

export async function getEditMetricForm(metricWrapper) {
  const { eventId, ...metricDataFromWrapper } = buildMetricFormFromWrapper(
    metricWrapper,
  );
  const { type: metricType, aggregator } = metricDataFromWrapper;
  let eventData;
  if (
    metricType === METRIC_TYPES.GLOBAL &&
    aggregator === MetricConstants.aggregationOptions.TOTAL_REVENUE
  ) {
    const [TOTAL_REVENUE] = GLOBAL_EVENTS;
    eventData = TOTAL_REVENUE;
  } else {
    eventData = flux.evaluateToJS(EventGetters.byId(eventId));
  }

  if (!eventData) {
    await Promise.all([
      ProjectActions.fetchAll(
        {
          project_status: ProjectEnums.project_status.ACTIVE,
        },
        { excludeFields: ['jira_integration'] },
      ),
      CurrentLayerActions.fetchAllCrossProjectMetrics(),
    ]);

    const eventsFullList = flux.evaluateToJS(
      MetricsPickerModuleGetters.queryableActiveProjectEventsMap,
    );
    eventData = eventsFullList[eventId] || {};
  }

  return {
    ...metricDataFromWrapper,
    event: eventData,
  };
}

export default {};
