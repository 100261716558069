import React from 'react';
import PropTypes from 'prop-types';
import SegmentTracking from 'optly/modules/segment';
import {
  Link,
  Input,
  Button,
  Textarea,
  ButtonRow,
  Attention,
  Sheet,
  Disclose,
} from 'optimizely-oui';

import Immutable, { toImmutable } from 'optly/immutable';
import Router from 'core/router';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import urls from 'optly/services/url_helper_legacy';
import LoadingOverlay from 'react_components/loading_overlay';
import {
  Form,
  formPropType,
  handleFormValidationError,
} from 'react_components/form';
import {
  actions as EventModuleActions,
  fns as EventModuleFns,
  enums as EventModuleEnums,
} from 'optly/modules/entity/event';
import ProjectFns from 'optly/modules/entity/project/fns';
import NavConstants from 'optly/services/navigation';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';
import { getters as CurrentEventGetters } from 'bundles/p13n/modules/current_event';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import EventProperties from 'bundles/p13n/components/event_properties';
import {
  populateEventPropertiesFromRequest,
  cleanEventPropertiesForRequest,
} from 'bundles/p13n/components/event_properties/utils';
import { getFormattedEventAPIName } from 'bundles/p13n/sections/oasis_implementation/section_module/fns';

@connect({
  canCreateUserEvent: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canCreateUserEvent,
  ],
  canUpdateUserEvent: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canUpdateUserEvent,
  ],
  currentProjectId: CurrentProjectGetters.id,
  customEvent: CurrentEventGetters.event,
  isFx: CurrentProjectGetters.isFlagsProject,
  events: CurrentProjectGetters.events,
  snippetGlobalName: [
    CurrentProjectGetters.project,
    ProjectFns.getSnippetGlobalName,
  ],
})
@Form({
  mapPropsToFormData: props => {
    const { customEvent, currentProjectId } = props;
    let currentlyEditingEvent = customEvent;
    if (!currentlyEditingEvent) {
      currentlyEditingEvent = toImmutable(
        EventModuleFns.createEventEntity({
          event_type: EventModuleEnums.eventTypes.CUSTOM,
          project_id: currentProjectId,
          api_name: null,
        }),
      );
    }
    return toImmutable({
      archived: false,
      category: 'other',
      event_filter: null,
      id: currentlyEditingEvent.get('id'),
      name: currentlyEditingEvent.get('name'),
      api_name: currentlyEditingEvent.get('api_name'),
      event_properties: currentlyEditingEvent.get('event_properties') || [],
      event_type: currentlyEditingEvent.get('event_type'),
      project_id: currentlyEditingEvent.get('project_id'),
      description: currentlyEditingEvent.get('description'),
    });
  },
  validateOnChange: false,
})
class ConfigCustomEvent extends React.Component {
  static componentId = 'config_custom_attribute';

  static propTypes = {
    canCreateUserEvent: PropTypes.bool.isRequired,
    canUpdateUserEvent: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    customEvent: PropTypes.object,
    events: PropTypes.instanceOf(Immutable.List).isRequired,
    form: formPropType.isRequired,
    isFx: PropTypes.bool,
    isStandAlone: PropTypes.bool,
    noRedirectOnCancel: PropTypes.bool,
    onSave: PropTypes.func,
    snippetGlobalName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isFx: false,
    isStandAlone: true,
    noRedirectOnCancel: false,
    customEvent: toImmutable({}),
    onSave: null,
  };

  constructor(props) {
    super(props);
    const { currentProjectId, isStandAlone } = this.props;
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      eventsDashUrl: urls.dataLayerTab(
        currentProjectId,
        NavConstants.DataLayerTabs.EVENT_TAB,
      ),
      isStandAlone,
      isSavingWebCustomEvent: false,
      eventPropertiesError: null,
      isApiNameTouched: false,
    };
    this.initializeForm();
  }

  componentDidMount() {
    const { form } = this.props;
    const event = form.getValue();

    if (event.get('event_type') !== EventModuleEnums.eventTypes.CUSTOM) {
      Router.go('/v2/projects');
    } else {
      const { event_properties } = populateEventPropertiesFromRequest(
        event.toJS(),
      );
      const eventProperties = form.field('event_properties');
      eventProperties.setValue(toImmutable(event_properties));
    }
  }

  initializeForm() {
    const { form } = this.props;
    form.field('name').validators({
      isFormValid: () => {
        const errors = this.validateForm();
        if (errors.name) {
          return tr('{0}', errors.name);
        }
      },
    });

    form.field('api_name').validators({
      isFormValid: () => {
        const errors = this.validateForm();
        if (errors.api_name) {
          return tr('{0}', errors.api_name);
        }
      },
    });

    form.field('description');
  }

  validateForm() {
    const { form, events } = this.props;
    return EventModuleFns.validateCustomEvent(
      form.getValue().toJS(),
      events.toJS(),
    );
  }

  getTitle() {
    const { form } = this.props;
    return form.getValue().get('id')
      ? tr('Edit Custom Event')
      : tr('New Custom Event');
  }

  isNewCustomEvent() {
    const { form } = this.props;
    return !form.getValue().get('id');
  }

  isAllowedToSave() {
    const { canCreateUserEvent, canUpdateUserEvent } = this.props;
    return this.isNewCustomEvent() ? canCreateUserEvent : canUpdateUserEvent;
  }

  renderApiCallText = () => {
    const { form, snippetGlobalName } = this.props;
    const EVENT_PROPERTIES_VALUES = {
      string: {
        example: '"example"',
        note: 'Please replace with actual data, in string format.',
      },
      number: {
        example: 0,
        note:
          'Please replace with actual data. This can be an integer, however strings are preferred.',
      },
      boolean: {
        example: false,
        note:
          'Please replace with actual data. This can be a boolean, however strings are preferred.',
      },
    };
    const eventProperties = form
      .field('event_properties')
      .getValue()
      .toJS();
    const clientApiNameFieldValue = form.field('api_name').getValue() || '';
    const mappedProperties = eventProperties
      .filter(
        property =>
          !!(property.name || property.value) &&
          !!(property.data_type || property.type),
      )
      .map(property => {
        const propertyName = property.name || property.value;
        const propertyType = property.data_type || property.type;
        return `${propertyName}: ${EVENT_PROPERTIES_VALUES[propertyType].example}, // ${EVENT_PROPERTIES_VALUES[propertyType].note}`;
      });
    const properties = eventProperties.length
      ? `,
  // The following event properties are all optional, please choose the ones you care about in your event.
  properties: {
    ${mappedProperties.join('\n    ')}
  }`
      : '';

    return (
      <pre>
        {`window['${snippetGlobalName}'] = window['${snippetGlobalName}'] || [];
window['${snippetGlobalName}'].push({
  type: "event",
  eventName: "${clientApiNameFieldValue}",
  tags: {
    revenue: 0, // Optional in cents as integer (500 == $5.00)
    value: 0.00 // Optional as float
  }${properties}
});`}
      </pre>
    );
  };

  saveCustomEvent = () => {
    const { form, onSave, customEvent } = this.props;
  
    Promise.all([form.validate(), this.validateEventProperties()])
      .then(() => {
        this.setState({
          isSavingWebCustomEvent: true,
        });
        ui.loadingStart('saveWebCustomEvent');
  
        const editingEvent = form.getValue().toJS();
        const cleanEvent = cleanEventPropertiesForRequest(editingEvent);
  
        const eventProperties = form.field('event_properties').getValue().toJS();
        const eventName = form.field('name').getValue();
        const apiName = form.field('api_name').getValue();
  
        if (this.isNewCustomEvent()) {
          // If the event is new, track all event properties
          const numberOfProperties = eventProperties.length;
          const propertyTypes = eventProperties.map(property => property.type);
          SegmentTracking.tracking.trackEvent('Event Created with Event Properties', {
            eventName,
            apiName,
            numberOfProperties,
            propertyTypes,
          });
            this.saveEvent(cleanEvent, onSave);
        } else {
          // For an existing event, track only newly added properties
          // To determine newly added properties, compare both the 'name' and 'type' field of the new and old properties
          let prevPropsNormalized = [];
          if (customEvent && customEvent.get('event_properties')) {
            const previousEventProperties = customEvent.get('event_properties').toJS();
            prevPropsNormalized = previousEventProperties.map(prop => ({
              name: prop.name,
              type: prop.data_type,
            }));
          }
  
          const newPropsNormalized = eventProperties.map(prop => ({
            name: prop.value,
            type: prop.type,
          }));
  
          const newProperties = newPropsNormalized.filter(newProp => {
            return !prevPropsNormalized.some(prevProp =>
              prevProp.name === newProp.name && prevProp.type === newProp.type
            );
          });
  
          const numberOfProperties = newProperties.length;
          const propertyTypes = newProperties.map(property => property.type);
  
          if (numberOfProperties > 0) {
            SegmentTracking.tracking.trackEvent('Event Updated with New Properties', {
              eventName,
              apiName,
              numberOfProperties,
              propertyTypes,
            });
          }
            this.saveEvent(cleanEvent, onSave);
        }
      })
      .catch(handleFormValidationError)
      .finally(() => {
        ui.loadingStop('saveWebCustomEvent');
        ui.loadingStop('createWebEventFromDashboard');
      });
  };
  
  saveEvent = (cleanEvent, onSave) => {
    EventModuleActions.save(cleanEvent)
      .then(event => {
        ui.showNotification({
          message: tr('Event saved'),
          type: 'success',
        });
        if (!onSave) {
          this.goToEventsDashboard();
        } else {
          onSave(event);
        }
        ui.hideDialog();
      })
      .fail(() => {
        ui.loadingStop('saveWebCustomEvent');
        ui.loadingStop('createWebEventFromDashboard');
        this.setState({
          isSavingWebCustomEvent: false,
        });
      });
  };
  
  goToEventsDashboard = () => {
    const { eventsDashUrl } = this.state;
    Router.go(eventsDashUrl);
    ui.hideDialog();
  };

  validateEventProperties = () => {
    const { form } = this.props;
    const eventProperties = form
      .field('event_properties')
      .getValue()
      .toJS();

    return new Promise((resolve, reject) => {
      const hasEmptyProperties = eventProperties.some(
        ({ value, type }) => !value || !type,
      );

      if (hasEmptyProperties) {
        reject();
        this.setState({
          eventPropertiesError:
            'Please enter a name and data type for all properties.',
        });
      } else {
        resolve();
        this.setState({
          eventPropertiesError: null,
        });
      }
    });
  };

  updateEventProperties = updatedProperties => {
    const { form } = this.props;
    const eventProperties = form.field('event_properties');
    eventProperties.setValue(toImmutable(updatedProperties));
  };

  onEventNameChange(event) {
    const { form, customEvent } = this.props;
    const { isApiNameTouched } = this.state;

    const newEventName = event.target.value;
    const nameField = form.field('name');
    nameField.setValue(newEventName);

    const clientApiNameField = form.field('api_name');
    let newEventAPIName;
    if (!isApiNameTouched && !customEvent.get('api_name')) {
      newEventAPIName = getFormattedEventAPIName(newEventName).toLowerCase();
    } else {
      newEventAPIName = getFormattedEventAPIName(clientApiNameField.getValue());
    }
    clientApiNameField.setValue(newEventAPIName);
  }

  onApiNameChange(event) {
    const { form } = this.props;
    const newApiName = event.target.value;
    const clientApiNameField = form.field('api_name');

    const formattedEventAPIName = getFormattedEventAPIName(newApiName);

    clientApiNameField.setValue(formattedEventAPIName);

    this.setState({
      isApiNameTouched: true,
    });
  }

  render() {
    const { form, noRedirectOnCancel, customEvent, isFx } = this.props;
    const {
      isStandAlone,
      isSavingWebCustomEvent,
      eventPropertiesError,
    } = this.state;
    const nameField = form.field('name');
    const clientApiNameField = form.field('api_name');
    const descriptionField = form.field('description');
    const eventProperties = form.field('event_properties').getValue();
    const customEventExperimentCount = customEvent.get('experiment_count') || 0;

    return (
      <Sheet title={this.getTitle()} onClose={ui.hideDialog}>
        <LoadingOverlay loadingId="saveWebCustomEvent">
          <div
            className={`${isStandAlone ? 'overflow-y--auto' : ''}`}
            data-test-section="configure-custom-event-component">
            <ol className="oui-form-fields">
              <li className="oui-form-field__item push-double--bottom">
                <Input
                  type="text"
                  onChange={event => this.onEventNameChange(event)}
                  value={nameField.getValue() ? nameField.getValue() : ''}
                  testSection="custom-event-name"
                  label="Name"
                  isRequired={true}
                  note={nameField.getErrors().message}
                  displayError={nameField.getErrors().hasError}
                  placeholder="Event name"
                />
              </li>
              <li>
                <div className="push-double--top push-double--bottom">
                  <Textarea
                    value={descriptionField.getValue() || ''}
                    label="Description"
                    placeholder="Add a description"
                    onChange={event =>
                      descriptionField.setValue(event.target.value)
                    }
                    testSection="custom-event-description"
                  />
                </div>
              </li>
              <li className="oui-form-field__item push--bottom">
                <Input
                  type="text"
                  onChange={event => this.onApiNameChange(event)}
                  value={
                    clientApiNameField.getValue()
                      ? clientApiNameField.getValue()
                      : ''
                  }
                  testSection="custom-event-api-name"
                  label="API Name"
                  isRequired={true}
                  note={clientApiNameField.getErrors().message}
                  displayError={clientApiNameField.getErrors().hasError}
                  placeholder="Enter a unique event identifier"
                />
              </li>
            </ol>
            <EventProperties
              eventProperties={eventProperties.toJS()}
              eventPropertiesError={eventPropertiesError}
              isEventUsedInExperiment={customEventExperimentCount > 0}
              isFx={isFx}
              updateEventProperties={this.updateEventProperties}
              sectionClassNames="push-quad--top"
            />
            <ol className="oui-form-fields">
              <li className="oui-form-field__item push-quad--bottom push-quad--top">
                <h5 className="weight--normal">Tracking Code</h5>
                <p>
                  To track this event, add the following code to your page.{' '}
                  <Link
                    href="https://docs.developers.optimizely.com/web/docs/event"
                    newWindow={true}>
                    Learn more
                  </Link>
                </p>
                <div className="push-double--top">
                  <Disclose title="API tracking code">
                    <Attention
                      type="brand"
                      alignment="left"
                      style={{ overflow: 'auto' }}>
                      {this.renderApiCallText()}
                    </Attention>
                  </Disclose>
                </div>
              </li>
            </ol>
            <div className="flex flex-align--center">
              <div className="oui-sheet__required-indicator cursor--default color--red">
                <span>* Required field</span>
              </div>
              <div className="flex--1">
                <ButtonRow
                  rightGroup={[
                    <Button
                      style="plain"
                      testSection="custom-event-cancel"
                      key="cancel"
                      onClick={
                        noRedirectOnCancel
                          ? ui.hideDialog
                          : this.goToEventsDashboard
                      }>
                      Cancel
                    </Button>,
                    <Button
                      testSection="custom-event-save"
                      style="highlight"
                      key="submit"
                      isDisabled={
                        !this.isAllowedToSave() || isSavingWebCustomEvent
                      }
                      onClick={this.saveCustomEvent}>
                      Save Event
                    </Button>,
                  ]}
                />
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default ConfigCustomEvent;
